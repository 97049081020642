<template>
        <div id="layout-admin">
                <header id="header">
                        <h1>I V E S - ADMIN</h1>
                        <div>
                                <NuxtLink class="btn icon" :to="userProfileLink">
                                        <font-awesome-icon icon="fa-solid fa-user" />
                                </NuxtLink>
                                <button class="btn icon" type="button" @click="onLogout">
                                        <font-awesome-icon icon="fa-solid fa-power-off"></font-awesome-icon>
                                </button>
                        </div>
                </header>
                <div class="with-sidebar">
                        <div id="sidebar">
                                <ul>
                                        <li v-for="(module, i) in modules" :key="i">
                                                <NuxtLink :to="module.link">{{ module.name }}</NuxtLink>
                                        </li>
                                </ul>
                        </div>
                        <div id="content">
                                <div class="container-fluid">
                                        <SharedBreadcrumb :items="breadcrumbs" />
                                        <slot />
                                </div>
                                <SharedFooter />
                        </div>
                </div>
        </div>
</template>

<script setup>
import { forceLogout } from "~/assets/js/utilities.js"
import layoutStyle from "~/assets/styles/styles-admin.scss?inline"
useHead({
        style: [layoutStyle]
})

const onLogout = () => {
        const message = "Logging out?"
        if (!confirm(message)) return
        useAuthorizedFetch("/admin/logout", {
                method: "DELETE",
                onResponse() {
                        forceLogout(useRoute())
                }
        })
}

const modules = [
        { name: "Dashboard", link: "/admin/dashboard" },
        { name: "Accounts", link: "/admin/accounts" },
        { name: "Billings", link: "/admin/billings" },
        { name: "Account Requested Plans", link: "/admin/account_requested_plans" },
        { name: "Caller IDs", link: "/admin/caller_ids" },
        { name: "Sms Maskings", link: "/admin/sms_maskings" },
        { name: "Campaigns", link: "/admin/campaigns" },
        { name: "Users", link: "/admin/users" },
        { name: "Audit Trails", link: "/admin/audit_trails" },
        { name: "Tools", link: "/admin/custom_emails" },
        { name: "CMS Accounts", link: "/admin/admin_users" },
        { name: "Inquiries", link: "/admin/inquiries" },
        { name: "Reports", link: "/admin/reports" },
]

const breadcrumbs = useBreadcrumbs()

const { userId } = useCredentials("ives.user")
const userProfileLink = computed(() => `/admin/admin_users/${userId()}`)
</script>

<style scoped>
#layout-admin {
        height: 100vh;
        overflow: hidden;
}

#header {
        height: 64px;

        padding: 0.5rem 1rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background: #258ffe;
        color: #fff;
}

#header h1 {
        margin: 0;
}

#header .icon {
        background: transparent;
        border: 0;
        padding: 0.5rem 1rem;
}

.with-sidebar {
        display: flex;
        flex-wrap: wrap;
        /* ↓ The default value is the first point on the modular scale */
        gap: var(--gutter, var(--s1));

        height: calc(100% - 64px);
}

.with-sidebar>* {
        height: 100%;
        overflow-y: auto;
}

.with-sidebar> :first-child {
        /* ↓ The width when the sidebar _is_ a sidebar */
        flex-basis: 24ch;
        flex-grow: 1;
}

.with-sidebar> :last-child {
        /* ↓ Grow from nothing */
        flex-basis: 0;
        flex-grow: 999;
        /* ↓ Wrap when the elements are of equal width */
        min-width: 50%;
}

#sidebar {
        /* TODO(aes): set appropriate value */
        border-right: 1px solid black;
}

#sidebar ul {
        padding: 0;
        list-style-type: none;
}

#sidebar a {
        display: block;
        padding-block: 0.5rem;
        padding-inline: 1rem;
        text-decoration: none;
}

#sidebar a:link,
#sidebar a:visited {}

#sidebar a:hover {
        /* TODO(aes): set appropriate value */
        background: #ccc;
}

#sidebar .router-link-active {
        /* TODO(aes): set appropriate value */
        background: #ddd;
}

#content .container-fluid {
        padding: 2rem;
        min-height: calc(100% - 70px);
}
</style>
