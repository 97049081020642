<template>
        <nav id="breadcrumbs" aria-label="breadcrumb">
                <ol class="breadcrumb">
                        <li v-for="item in items" :key="item.name" class="breadcrumb-item d-flex align-items-center"
                                :class="{ active: !item.link }" :aria-current="!item.link ? 'page' : null">
                                <NuxtLink v-if="!(current.link == item.link)" :to="item.link">
                                        {{ item.name }}
                                </NuxtLink>
                                <span v-else>
                                        {{ item.name }}
                                </span>
                        </li>
                        <li id="actions" v-if="current.actions">
                                <template v-for="action in current.actions" :key="action.name">
                                        <!-- TODO(aes): find out why :target="" doesn't work -->
                                        <NuxtLink v-if="action.link" class="btn btn-primary" :to="action.link"
                                                :target="action.target">
                                                {{ action.name }}
                                        </NuxtLink>
                                        <DropdownMenu v-else-if="action.dropdown && current.resource" class="d-inline ml-1"
                                                :title="action.name" :items="action.dropdown(unref(current.resource))" />
                                        <button v-else-if="action.operation && action.operation.enabled(unref(current.resource))"
                                                type="button" class="btn btn-primary ml-1"
                                                @click="action.operation.handler({ params: useRoute().params })">
                                                {{ action.name }}
                                        </button>
                                        <button v-else-if="action.dialog && action.dialog.enabled(unref(current.resource))"
                                                class="btn btn-primary"
                                                @click="showDialog({ id: unref(current.resource)?.id, dialog: action.dialog, model: action.model })">
                                                {{ action.name }}
                                        </button>
                                </template>
                        </li>
                </ol>

                <!-- TODO(aes): should this be here? -->
                <div ref="modal" class="modal" tabindex="-1">
                        <div class="modal-dialog">
                                <div class="modal-content">
                                        <div class="modal-header">
                                                <h5 class="modal-title">{{ modalSettings.title }}</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                </button>
                                        </div>
                                        <div class="modal-body">
                                                <label>
                                                        <span class="mr-2">{{ modalSettings.label }}</span>
                                                        <input v-model="modalForm" type="text"
                                                                :placeholder="modalSettings.placeholder" required>
                                                </label>
                                        </div>
                                        <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" @click="handleDialog">OK</button>
                                                <button type="button" class="btn btn-secondary"
                                                        data-dismiss="modal">Cancel</button>
                                        </div>
                                </div>
                        </div>
                </div>
        </nav>
</template>

<script setup>
const props = defineProps([
        "items",
])

const modules = [
        { name: "Dashboard", link: "/admin/dashboard" },
        { name: "Accounts", link: "/admin/accounts" },
        { name: "Billings", link: "/admin/billings" },
        { name: "Account Requested Plans", link: "/admin/account_requested_plans" },
        { name: "Caller IDs", link: "/admin/caller_ids" },
        { name: "Sms Maskings", link: "/admin/sms_maskings" },
        { name: "Campaigns", link: "/admin/campaigns" },
        { name: "Users", link: "/admin/users" },
        { name: "Audit Trails", link: "/admin/audit_trails" },
        { name: "Tools", link: "/admin/custom_emails" },
        { name: "CMS Accounts", link: "/admin/admin_users" },
        { name: "Inquiries", link: "/admin/inquiries" },
        { name: "Reports", link: "/admin/reports" },
]

const current = computed(() => props.items?.length && props.items[props.items.length - 1])

// TODO(aes): should this be here?
const modal = ref(null)
const modalSettings = ref({})
const modalForm = ref(null)
const handleDialog = ref(null)
const showDialog = ({ dialog, id, model }) => {
        modalSettings.value = {
                title: dialog.title,
                label: dialog.label,
                placeholder: dialog.placeholder,
        }
        handleDialog.value = () => useHandle(model, dialog.name)({
                ...useRoute().params,
                form: modalForm.value,
                onSuccess(data) {
                        if (data?.error) {
                                alert(data.error)
                        }
                },
        })
        $(modal.value).modal("show")
}
</script>

<style scoped>
#breadcrumbs #actions {
        margin-inline-start: auto;
}

#breadcrumbs #actions>*:not(:first-child) {
        margin-inline-start: 0.5rem;
}
</style>
